<template>
  <div
    class="column_center flex"
    v-loading="loading"
    element-loading-text="拼命加载中"
  >
    <div class="center_left">
      <div class="avatar cursorP" v-if="center.authorInfo">
        <img
          :src="center.authorInfo.avatar"
          alt
          :title="center.authorInfo.realname"
          class="err_avatar"
        />
        <div class="name">{{ center.authorInfo.realname }}</div>
        <div class="text">
          {{ center.authorInfo.breifdescrition }}
        </div>
        <div class="attention" @click.stop="look(center.authoreid)">
          <el-button
            :type="center.userInfo.focus == 0 ? 'primary ' : 'info'"
            size="mini"
            round
            :class="center.userInfo.focus == 0 ? 'primary' : 'info_btn'"
          >
            <span v-if="center.userInfo.focus == 0"
              ><i class="el-icon-plus"></i> 关注</span
            >
            <span v-else>已关注</span>
          </el-button>
        </div>
      </div>
    </div>
    <div class="center_right">
      <ul class="list" v-if="list">
        <li class="box relative" v-for="(item, index) in list" :key="index">
          <div class="info flex relative" v-if="item.authorInfo">
            <router-link
              class="avatar"
              :to="'./sectionCenter?id=' + item.authoreid"
              target="_blank"
            >
              <img :src="item.authorInfo.avatar" alt="" class="err_avatar" />
            </router-link>
            <router-link
              :to="'/section/sectionCenter?id=' + item.authoreid"
              :title="item.authorInfo.realname"
              target="_blank"
              class="name"
            >
              {{ item.authorInfo.realname }}
              <p>{{ item.authorInfo.breifdescrition }}</p>
            </router-link>
          </div>
          <h2 @click="goRouter('/section/sectionDetail/', item.id)">
            <a href="javascript:void(0);" :title="item.name">
              <img
                src="~/static/column/top.png"
                alt=""
                v-show="item.hasTop == 1"
              />
              {{ item.name }}
            </a>
          </h2>
          <div class="text flex">
            <viewer :images="[item.reamark1]" class="viewer">
              <img :src="item.reamark1" alt="" width="200" title="图片" />
            </viewer>
            <span
              @click="goRouter('/section/sectionDetail/', item.id)"
              class="line_clamp3"
              >{{ item.gaidesc }}</span
            >
          </div>
          <div class="features">
            <span
              ><img src="~/static/ask/read.png" alt="" />{{
                item.clicks
              }}阅读</span
            >
            <span
              ><img src="~/static/ask/comments.png" alt="" />{{
                item.jsonObject.recomands
              }}评论</span
            >
            <span
              class="praise"
              @click="collectCloumn(item.id, item)"
              :class="item.userInfo.goods == 0 ? '' : 'success'"
            >
              <img src="~/static/ask/zan.png" alt="" class="img" />
              <img src="~/static/ask/zan_green.png" alt="" class="green" />
              {{ item.jsonObject.goods }}点赞
            </span>
            <div class="type">#{{ item.fenclass }}</div>
          </div>
        </li>
      </ul>
      <div
        class="section_more"
        v-if="isMore"
        @click.stop="mores(0)"
        v-loading="loading"
      >
        点击加载更多
      </div>
    </div>
    <login-prompt
      :goShow="isHide"
      @close="handleClose"
      title="登录"
      :text="title"
    ></login-prompt>
  </div>
</template>

<script>
import loginPrompt from "@/components/public/loginPrompt";
export default {
  name: "askCenter",
  components: {
    loginPrompt,
  },
  data() {
    return {
      center: [],
      list: [],
      page: {
        pageNum: 1,
        pageSize: 5,
        total: 0,
        id: "",
      },
      attention: false,
      image: [
        "http://p1.ifengimg.com/fck/2018_41/0d69532a98f88b2_w1080_h1284.jpg",
        "http://file.digitaling.com/eImg/uimages/20171017/1508207431954401.jpg",
        "http://d.ifengimg.com/w600/p0.ifengimg.com/pmop/2018/0518/D7835C96A3D231F642869517956447AF7A745D49_size82_w1080_h530.jpg",
      ],
      isHide: false,
      title: "",
      loading: false,
      isMore: true,
    };
  },
  methods: {
    async info() {
      this.page.id = this.$route.query.id;
      var res = await this.$api.queryAutherCenterApi(this.page);
      if (res.data.success) {
        this.center = res.data.data.records[0];
        this.list = res.data.data.records;
        this.page.total = res.data.data.total;
      }
    },
    //关注
    look(id) {
      if (localStorage.getItem("userType")) {
        this.$api.focusAuthorApi({ id: id }).then((res) => {
          if (res.data.success) {
            this.center.userInfo.focus = 1 ^ this.center.userInfo.focus;
            if (this.center.userInfo.focus) {
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
            }
          }
        });
      } else {
        this.title = "登录成功后才可已关注！";
        this.isHide = true;
      }
    },
    //点赞
    collectCloumn(id, info) {
      if (localStorage.getItem("userType")) {
        this.$api.collectCloumnApi({ id: id }).then((res) => {
          var goods = Number(info.jsonObject.goods);
          info.userInfo.goods = 1 ^ info.userInfo.goods;
          if (info.userInfo.goods) {
            info.jsonObject.goods = Number(info.jsonObject.goods) + 1;
            this.$message.success(res.data.msg);
          } else {
            info.jsonObject.goods -= 1;
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.title = "登录成功后才可已点赞！";
        this.isHide = true;
      }
    },
    //点击加载
    mores(i) {
      this.page.pageNum += 1;
      if (i == 0) {
        this.info();
        // this.$message.warning("暂无更多");
      } else {
        this.getAuthorArticle();
      }
    },
    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },

    handleClose() {
      this.isHide = false;
    },
    //滚动下拉
    scrollBottom() {
      // 变量scrollTop为当前页面的滚动条纵坐标位置
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 变量 windowHeight 是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      // 变量 scrollHeight 是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollHeight - (scrollTop + windowHeight) < 350) {
        if (this.page.total != this.list.length) {
          this.isMore = true;
        } else {
          this.isMore = false;
        }
      }
    },
  },
  created() {
    this.info();
    //判断是否移动端
    this.$mobile(
      "https://lzmz.cdpee.org.cn/#/pages/community/cloumn/authorCenter?id=" +
        this.$route.query.id
    );
  },
  mounted() {
    this.$emit("goRouter", "/section/");
    window.addEventListener("scroll", this.scrollBottom);
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/column.less";
</style>
